import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ProtectedRoute } from '../../components/atoms';
import { BioScreen, ContactScreen, EventsScreen, HomeScreen, MerchandiseScreen, MixtapesScreen } from '../../screens';

const homeScreens = [
  <Route
    exact
    path="/"
    render={() => {
        return (
          <Redirect to="/home" />
        )
    }}
  />,
  <ProtectedRoute
    path='/home'
    exact
    component={HomeScreen}
  />,
];

const bioScreens = [
  <ProtectedRoute
    path='/bio'
    exact
    routeToAuthenticate="bio"
    component={BioScreen}
  />,
];

const contactScreens = [
  <ProtectedRoute
    path='/contact'
    exact
    routeToAuthenticate="contact"
    component={ContactScreen}
  />,
];

const merchandiseScreens = [
  <ProtectedRoute
    path='/merchandise'
    exact
    routeToAuthenticate="merchandise"
    component={MerchandiseScreen}
  />,
];

const eventsScreens = [
  <ProtectedRoute
    path='/events'
    exact
    routeToAuthenticate="events"
    component={EventsScreen}
  />,
];

const mixtapesScreens = [
  <ProtectedRoute
    path='/mixtapes'
    exact
    routeToAuthenticate="events"
    component={MixtapesScreen}
  />,
];

export const AppRouter = [
  homeScreens,
  bioScreens,
  contactScreens,
  merchandiseScreens,
  eventsScreens,
  mixtapesScreens,
];

export const AppModalFormsRouter = [];
