/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement, ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";

export const HomeNav = ({
  children,
  toggle,
}: {
  children: ReactNode;
  toggle: string;
}): ReactElement => {
  const history = useHistory();
  const [navbar, setNavbar] = useState(false);

  const _home = () => {
    history.push("/home");
  };

  const _bio = () => {
    history.push("/bio");
  };

  const _merchandise = () => {
    history.push("/merchandise");
  };

  const _contact = () => {
    history.push("/contact");
  };

  const _events = () => {
    history.push("/events");
  };

  const _mixtapes = () => {
    history.push("/mixtapes");
  };

  const _navbarChange = () => setNavbar(!navbar);

  const navStyle =
    "text-white hover:text-xencode-gold hover:cursor-pointer font-abeakrg";

  return (
    <div className="h-screen w-screen">
      <nav className="w-full relative z-50 md:px-0 px-8">
        <div className="justify-center py-4 mx-auto lg:px-72 md:items-center md:flex md:px-8">
          <div>
            <div className="flex items-center justify-center py-3 md:py-5 md:block">
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-white focus:border"
                  onClick={_navbarChange}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 20 20"
                      fill="white"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="z-20">
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 z-20 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <ul className="items-center justify-center space-y-8 md:flex md:space-x-12 md:space-y-0 text-sm">
                <li
                  className={`${navStyle} ${
                    toggle === "home" ? "text-xencode-gold" : ""
                  }`}
                >
                  <a onClick={_home}>HOME</a>
                </li>
                <li
                  className={`${navStyle} ${
                    toggle === "bio" ? "text-xencode-gold" : ""
                  }`}
                >
                  <a onClick={_bio}>BIO</a>
                </li>
                <li
                  className={`${navStyle} ${
                    toggle === "merch" ? "text-xencode-gold" : ""
                  }`}
                >
                  <a onClick={_merchandise}>MERCHANDISE</a>
                </li>
                <li
                  className={`${navStyle} ${
                    toggle === "events" ? "text-xencode-gold" : ""
                  }`}
                >
                  <a onClick={_events}>EVENTS</a>
                </li>
                <li
                  className={`${navStyle} ${
                    toggle === "mixtapes" ? "text-xencode-gold" : ""
                  }`}
                >
                  <a onClick={_mixtapes}>MIXTAPES</a>
                </li>
                <li
                  className={`${navStyle} ${
                    toggle === "contact" ? "text-xencode-gold" : ""
                  }`}
                >
                  <a onClick={_contact}>CONTACT</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div>{children}</div>
    </div>
  );
};
