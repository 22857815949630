import React, { useEffect } from "react";
import { AppLayout, ContactForm } from "../../../components";

export const ContactScreen = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const HomeBackground = require("../../../assets/images/home/home-background.jpg");
  const ContactText = require("../../../assets/images/contact/contact-text.png");

  return (
    <AppLayout toggle="contact">
      <img
        src={HomeBackground}
        alt="background"
        className="bg-black h-[500px] w-full object-cover z-10 mt-12"
      />
      <div className="relative px-4 bg-white flex justify-center">
        <div className="mt-12 w-full md:px-24 px-4 md:max-w-[600px]">
          <ContactForm />
        </div>
        <div className="lg:block hidden self-center px-24 md:px-4">
          <img
            src={ContactText}
            alt="background"
            className="object-contain w-[500px]"
          />
        </div>
      </div>
    </AppLayout>
  );
};
