import React from 'react';

import { Nav, HomeNav } from '../../molecules';
import { Footer } from '../../atoms';

interface AppProps {
  children: any;
  isHome?: boolean;
  toggle?: string;
}

export const AppLayout: React.FC<AppProps> = ({children, isHome = false, toggle = ''}) => {
  return (
    <div className="flex bg-black bg-repeat shadow">
      {isHome ? 
        <HomeNav toggle={toggle}>
          <>
            {children}
            <Footer toggle={toggle} />
          </>
        </HomeNav> : 
        <Nav toggle={toggle}>
          <>
            {children}
            <Footer toggle={toggle} />
          </>
        </Nav>}
    </div>
  );
};
