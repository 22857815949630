import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppLayout } from "../../../components";

export const MerchandiseScreen = () => {
  const history = useHistory();
  const _contact = () => {
    history.push("/contact");
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  const HeaderBackground = require("../../../assets/images/home/banner 3.png");
  const Product1 = require("../../../assets/images/merchandise/product_1.png");
  const Product2 = require("../../../assets/images/merchandise/product_2.jpg");
  const Product3 = require("../../../assets/images/merchandise/product_3.jpg");
  const Product4 = require("../../../assets/images/merchandise/product_4.jpg");
  const Product5 = require("../../../assets/images/merchandise/product_5.jpg");
  const Product6 = require("../../../assets/images/merchandise/product_6.jpg");
  const Product7 = require("../../../assets/images/merchandise/product_7.jpg");
  const Product8 = require("../../../assets/images/merchandise/product_8.jpg");
  const Product9 = require("../../../assets/images/merchandise/product_9.jpg");
  const Product10 = require("../../../assets/images/merchandise/product_10.jpg");
  const Product11 = require("../../../assets/images/merchandise/product_11.jpg");
  const Product12 = require("../../../assets/images/merchandise/product_12.jpg");
  const Product13 = require("../../../assets/images/merchandise/product_13.jpg");
  const Product14 = require("../../../assets/images/merchandise/product_14.jpg");
  const Product15 = require("../../../assets/images/merchandise/product_15.jpg");

  const ProductCard = ({ image }: { image: any }) => {
    return (
      <img
        src={image}
        alt="background"
        className="bg-black max-w-md w-72 h-80 object-cover border-[2px] border-gray-900"
      />
    );
  };

  const MerchandiseBody = () => {
    return (
      <>
        <div className="text-center py-14 bg-black">
          <h1 className="text-xencode-gold text-lg text-center mb-3 font-poppins">
            AllOver Logo TrackSuit
          </h1>
          <p className="text-white text-sm">Sizes</p>
          <p className="text-white text-sm">M L XL XXL</p>
          <div className="text-center space-y-1 mt-2 font-poppins">
            <div className="w-full flex justify-center pt-2 pb-12">
              <button
                onClick={_contact}
                className={`p-1 text-xs absolute text-white font-semibold bg-xencode-gold hover:text-black rounded-3xl px-4 outline-none`}
                type="submit"
              >
                Enquire
              </button>
            </div>
          </div>
        </div>
        <div className="flex z-40 pb-8 bg-black w-full justify-center">
          <div className="md:grid grid-cols-3 grid-flow-row gap-10 space-y-10 md:space-y-0">
            <ProductCard image={Product1} />
            <ProductCard image={Product2} />
            <ProductCard image={Product3} />
            <ProductCard image={Product4} />
            <ProductCard image={Product5} />
            <ProductCard image={Product6} />
          </div>
        </div>
        <div className="text-center py-14 bg-black">
          <h1 className="text-xencode-gold text-lg text-center mb-3 font-poppins">
            Logo Print Cotton T-shirts Coming Soon!
          </h1>
          <p className="text-white text-sm">Sizes</p>
          <p className="text-white text-sm">M L XL XXL</p>
          <div className="text-center space-y-1 mt-2 font-poppins">
            <div className="w-full flex justify-center pt-2 pb-12">
              <button
                onClick={_contact}
                className={`p-1 text-xs absolute text-white font-semibold bg-xencode-gold hover:text-black rounded-3xl px-4 outline-none`}
                type="submit"
              >
                Enquire
              </button>
            </div>
          </div>
        </div>
        <div className="flex z-40 pb-8 bg-black w-full justify-center">
          <div className="md:grid grid-cols-3 grid-flow-row gap-10 space-y-10 md:space-y-0">
            <ProductCard image={Product7} />
            <ProductCard image={Product8} />
            <ProductCard image={Product9} />
            <ProductCard image={Product10} />
            <ProductCard image={Product11} />
            <ProductCard image={Product12} />
            <ProductCard image={Product13} />
            <ProductCard image={Product14} />
            <ProductCard image={Product15} />
          </div>
        </div>
      </>
    );
  };
  return (
    <AppLayout toggle="merch">
      <img
        src={HeaderBackground}
        alt="background"
        className="bg-black h-[500px] w-full object-cover z-10 mt-12"
      />
      <MerchandiseBody />
    </AppLayout>
  );
};
