import React, { useEffect } from "react";
import { AppLayout } from "../../../components";

export const MixtapesScreen = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const MixtapeBackground = require("../../../assets/images/mixtapes/banner 2.png");

  const SoundCloudSong1 = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="166"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1447516144&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
        <div
          className={
            "font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
          }
        >
          <a
            href="https://soundcloud.com/officialdjflava"
            title="DJ Flava"
            target="_blank"
            style={{ color: "#cccccc", textDecoration: "none" }}
            rel="noreferrer"
          >
            DJ Flava
          </a>{" "}
          ·
          <a
            href="https://soundcloud.com/officialdjflava/lets-party-naija-mixtape-vol-43"
            title="Let&#x27;s Party Naija Mixtape Vol 43"
            target="_blank"
            style={{ color: "#cccccc", textDecoration: "none" }}
            rel="noreferrer"
          >
            Let&#x27;s Party Naija Mixtape Vol 43
          </a>
        </div>
      </div>
    );
  };
  const SoundCloudSong2 = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="166"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1430842762&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
        <div
          className={
            "font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
          }
        >
          <a
            href="https://soundcloud.com/officialdjflava"
            title="DJ Flava"
            target="_blank"
            style={{ color: "#cccccc", textDecoration: "none" }}
            rel="noreferrer"
          >
            DJ Flava
          </a>{" "}
          ·
          <a
            href="https://soundcloud.com/officialdjflava/pushin-piano-mixtape-vol-17"
            title="Pushin Piano Mixtape Vol 17"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#cccccc", textDecoration: "none" }}
          >
            Pushin Piano Mixtape Vol 17
          </a>
        </div>
      </div>
    );
  };

  const SoundCloudSong3 = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="166"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1412326303&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
        <div
          className={
            "font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"
          }
        >
          <a
            href="https://soundcloud.com/officialdjflava"
            title="DJ Flava"
            target="_blank"
            style={{ color: "#cccccc", textDecoration: "none" }}
            rel="noreferrer"
          >
            DJ Flava
          </a>{" "}
          ·
          <a
            href="https://soundcloud.com/officialdjflava/pushin-piano-mixtape-vol-16-mp3"
            title="Pushin Piano Mixtape Vol 16"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#cccccc", textDecoration: "none" }}
          >
            Pushin Piano Mixtape Vol 16
          </a>
        </div>
      </div>
    );
  };

  const AudioMacSong1 = () => {
    return (
      <div>
        <iframe
          src="https://audiomack.com/embed/flavamusic/song/lets-party-naija-mixtape-vol-43"
          scrolling="no"
          width="100%"
          height="252"
        />
      </div>
    );
  };

  const AudioMacSong2 = () => {
    return (
      <div>
        <iframe
          src="https://audiomack.com/embed/flavamusic/song/pushin-piano-mixtape-vol-17"
          scrolling="no"
          width="100%"
          height="252"
        />
      </div>
    );
  };

  const AudioMacSong3 = () => {
    return (
      <div>
        <iframe
          src="https://audiomack.com/embed/flavamusic/song/lets-party-naija-mixtape-vol-41"
          scrolling="no"
          width="100%"
          height="252"
        />
      </div>
    );
  };

  const MerchandiseBody = () => {
    return (
      <>
        <h1 className="text-xencode-gold w-full text-center text-3xl mt-8 bg-black">
          audiomack
        </h1>
        <div className="flex z-40 py-24 bg-black w-full justify-center">
          <div className="md:grid grid-cols-3 grid-flow-row gap-10 space-y-10 md:space-y-0">
            <AudioMacSong1 />
            <AudioMacSong2 />
            <AudioMacSong3 />
          </div>
        </div>
        <h1 className="text-xencode-gold w-full text-center text-3xl bg-black">
          SoundCloud
        </h1>
        <div className="flex z-40 py-24 bg-black w-full justify-center">
          <div className="md:grid grid-cols-3 grid-flow-row gap-10 space-y-10 md:space-y-0">
            <SoundCloudSong1 />
            <SoundCloudSong2 />
            <SoundCloudSong3 />
          </div>
        </div>
      </>
    );
  };

  return (
    <AppLayout toggle="mixtapes">
      <img
        src={MixtapeBackground}
        alt="background"
        className="bg-black h-[500px] w-full object-cover z-10 mt-12"
      />
      <MerchandiseBody />
    </AppLayout>
  );
};
