import React, { useEffect } from "react";
import { AppLayout } from "../../../components";

export const EventsScreen = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const EventsBackground = require("../../../assets/images/events/banner1.png");
  const Event1 = require("../../../assets/images/events/event_1.JPG");
  const Event2 = require("../../../assets/images/events/event_2.JPG");
  const Event3 = require("../../../assets/images/events/event_3.JPG");
  const Event4 = require("../../../assets/images/events/event_4.JPG");
  const Event5 = require("../../../assets/images/events/event_5.JPG");
  const Event6 = require("../../../assets/images/events/event_6.JPG");
  const Event7 = require("../../../assets/images/events/event_7.JPG");
  const Event8 = require("../../../assets/images/events/event_8.JPG");
  const Event9 = require("../../../assets/images/events/event_9.JPG");
  const Event10 = require("../../../assets/images/events/event_10.JPG");
  const Event11 = require("../../../assets/images/events/event_11.JPG");
  const Event12 = require("../../../assets/images/events/event_12.JPG");
  const Event13 = require("../../../assets/images/events/event_13.JPG");
  const Event14 = require("../../../assets/images/events/event_14.JPG");
  const Event15 = require("../../../assets/images/events/event_15.JPG");
  const Event16 = require("../../../assets/images/events/event_16.JPG");
  const Event17 = require("../../../assets/images/events/event_17.JPG");
  const Event18 = require("../../../assets/images/events/event_18.JPG");
  const Event19 = require("../../../assets/images/events/event_19.JPG");
  const Event20 = require("../../../assets/images/events/event_20.JPG");
  const Event21 = require("../../../assets/images/events/event_21.JPG");
  const Event22 = require("../../../assets/images/events/event_22.JPG");
  const Event23 = require("../../../assets/images/events/event_23.JPG");
  const Event24 = require("../../../assets/images/events/event_24.JPG");
  const Event25 = require("../../../assets/images/events/event_25.JPG");
  const Event26 = require("../../../assets/images/events/event_26.JPG");
  const Event27 = require("../../../assets/images/events/event_27.JPG");
  const Event28 = require("../../../assets/images/events/event_28.JPG");
  const Event29 = require("../../../assets/images/events/event_29.JPG");
  const Event30 = require("../../../assets/images/events/event_30.JPG");
  const Event31 = require("../../../assets/images/events/event_31.JPG");
  const Event32 = require("../../../assets/images/events/event_32.JPG");
  const Event33 = require("../../../assets/images/events/event_33.JPG");
  const Event34 = require("../../../assets/images/events/event_34.jpg");
  const Event35 = require("../../../assets/images/events/event_35.PNG");
  const Event36 = require("../../../assets/images/events/event_36.JPG");
  const Event37 = require("../../../assets/images/events/event_37.JPG");
  const Event38 = require("../../../assets/images/events/event_38.JPG");
  const Event39 = require("../../../assets/images/events/event_39.JPG");
  const Event40 = require("../../../assets/images/events/event_40.JPG");
  const Event41 = require("../../../assets/images/events/event_41.jpeg");
  const Event42 = require("../../../assets/images/events/event_42.jpeg");
  const Event43 = require("../../../assets/images/events/event_43.jpeg");
  const Event44 = require("../../../assets/images/events/event_44.jpeg");

  const EventsHeader = () => {
    return (
      <div
        className="flex justify-center items-center z-40 h-[500px] w-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${EventsBackground})`,
        }}
      />
    );
  };

  const EventCard = ({ image }: { image: any }) => {
    return (
      <img
        src={image}
        alt="background"
        className="bg-black max-w-md w-72 mt-4 object-contain border-[2px] border-xencode-gold hover:scale-105 animate-none"
      />
    );
  };

  return (
    <AppLayout toggle="events">
      <EventsHeader />
      <div className="flex z-40 pb-8 bg-black w-full justify-center mt-8">
        <div className="lg:grid lg:grid-cols-4 grid-flow-row gap-10">
          <EventCard image={Event44} />
          <EventCard image={Event43} />
          <EventCard image={Event42} />
          <EventCard image={Event41} />
          <EventCard image={Event4} />
          <EventCard image={Event5} />
          <EventCard image={Event6} />
          <EventCard image={Event7} />
          <EventCard image={Event8} />
          <EventCard image={Event9} />
          <EventCard image={Event11} />
          <EventCard image={Event13} />
          <EventCard image={Event14} />
          <EventCard image={Event15} />
          <EventCard image={Event16} />
          <EventCard image={Event17} />
          <EventCard image={Event18} />
          <EventCard image={Event19} />
          <EventCard image={Event20} />
          <EventCard image={Event21} />
          <EventCard image={Event22} />
          <EventCard image={Event23} />
          <EventCard image={Event24} />
          <EventCard image={Event25} />
          <EventCard image={Event26} />
          <EventCard image={Event27} />
          <EventCard image={Event28} />
          <EventCard image={Event29} />
          <EventCard image={Event30} />
          <EventCard image={Event31} />
          <EventCard image={Event32} />
          <EventCard image={Event33} />
          <EventCard image={Event34} />
          <EventCard image={Event35} />
          <EventCard image={Event36} />
          <EventCard image={Event37} />
          <EventCard image={Event38} />
          <EventCard image={Event39} />
          <EventCard image={Event40} />
          <EventCard image={Event1} />
          <EventCard image={Event2} />
          <EventCard image={Event3} />
          <EventCard image={Event10} />
          <EventCard image={Event12} />
        </div>
      </div>
    </AppLayout>
  );
};
