import React, { useEffect, useState } from "react";
import { AppLayout } from "../../../components";
import { Card } from "./Card";
import { Carroussel } from "./Carousel";

export const HomeScreen = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const HomeBackground = require("../../../assets/images/merchandise/banner 4.png");
  const AlbumCover1 = require("../../../assets/images/home/album-cover-1.png");
  const AlbumCover2 = require("../../../assets/images/home/album-cover-2.png");
  const AlbumCover3 = require("../../../assets/images/home/album-cover-3.png");

  let cards = [
    {
      key: 1,
      content: <Card imagen={AlbumCover1} />,
    },
    {
      key: 2,
      content: <Card imagen={AlbumCover2} />,
    },
    {
      key: 3,
      content: <Card imagen={AlbumCover3} />,
    },
  ];
  const HomeBody = () => {
    return (
      <div className="flex justify-center z-40 h-full w-full absolute">
        <div className="space-y-4 mt-12">
          <h1 className="text-white text-6xl text-center font-poppins">
            DJ FLAVA
          </h1>
          <h2 className="text-white text-sm font-extrabold text-center font-caveat">
            DISK JOCKEY - ARTISTE - PRODUCER
          </h2>
          <div
            className={`flex items-center justify-center md:w-[800px] w-[320px] md:pt-48 pt-24 ml-1`}
          >
            <Carroussel
              cards={cards}
              height="500px"
              width="100%"
              margin="0 auto"
              offset={100}
              showArrows={false}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <AppLayout isHome toggle="home">
      <HomeBody />
      <img
        src={HomeBackground}
        alt="background"
        className="bg-transparent h-screen w-full object-cover"
      />
    </AppLayout>
  );
};
