import React, { useEffect } from "react";
import { AppLayout } from "../../../components";
import Modal from "@mui/material/Modal";

export const BioScreen = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const DjFlavaColorMain = require("../../../assets/images/bio/Bio pic.png");
  const HeadphonesLogo = require("../../../assets/images/bio/headphones.png");
  const DurationLogo = require("../../../assets/images/bio/duration.png");
  const MediaLogo = require("../../../assets/images/bio/media.png");
  const CategoriesLogo = require("../../../assets/images/bio/categories.png");
  const GenreLogo = require("../../../assets/images/bio/genre.png");
  const GenresLogo = require("../../../assets/images/bio/genres.png");

  const QuestionComponent = ({ question }, { question: string }) => {
    return (
      <div className="border-[1px] border-xencode-gold border-r-2 py-2 px-4 my-8 rounded-2xl w-fit">
        <p className="text-white">{question}</p>
      </div>
    );
  };
  const AnswerComponent = ({ answer }, { answer: string }) => {
    return <p className="text-white break-words tracking-wider">{answer}</p>;
  };
  return (
    <>
      <div>
        <Modal open={open} onClose={handleClose}>
          <div className="w-full h-full bg-black text-white p-4 md:p-12 overflow-auto">
            <div className="flex justify-between mb-24">
              <h1 className="md:text-5xl self-center text-2xl">
                The world is listening to us now
              </h1>
              <div className="self-center">
                <button
                  onClick={handleClose}
                  className="hover:text-red-600 font-bold"
                >
                  Close
                </button>
              </div>
            </div>
            <QuestionComponent question="It's an honour to speak with you today. Why don't you give us some details about yourself and your story? sHow did you get to where you are today?" />
            <AnswerComponent
              answer="It's an honour to speak with you today. Why don't you give us some details about yourself and your story? How did you get to where you are today?
            The pleasure is mine. My name is Azeez Jagun, popularly known as DJ Flava. An international Multi-Genre DJ with many years of experience.

            I have played around the world in about 25 countries so far experiencing great culture and humanity. I also have a clothing line that celebrates the pop
            culture with aninclusion of themed streetstyle. I was born in Lagos, Nigeria and I’m currently based in Dubai, UAE. I have always loved music and fashion
            since middle school.The music and fashion business really fascinated me throughout my school days in Nigeria and it kept me motivated. Equality
            and determination is the key to longevity. The African music culture is growing and expanding and we are benefitting from the global
            dominance. The world is listening to us now."
            />
            <QuestionComponent question="I'm sure your success has not come easily. What challenges have you had to overcome along the way?" />
            <AnswerComponent
              answer="Well, there’s no business without obstacles or as I see them, stepping stones. They are there to make us stronger. I have had a couple of encounters
            which some of them happens to be online substandard fashion materials and late deliveries, shortage of staff/labour. Sometimes you’ve got an event
            planned to the tee and life comes and upends everything, that’s when you have to remain focused on the end goal. We struggle a lot with copyright issues
            when creating mixes for content. Ihave mixes on major platforms, however some platforms remove our mixes making it difficult to stay relevant and be
            creative. I have Afrobeat mixtapes from volume 1-42 available on Soundcloud, Audiomack, as well as, YouTube and you can find them @ DJ Flava."
            />
            <QuestionComponent question="Let's talk about the work you do. What do you specialize in and why should someone work with you above the competition?" />
            <AnswerComponent
              answer="As an international Multi Genre DJ, I specialize in allkinds of sounds, music and genres alternating from Hiphop to Afrobeats, Amapiano, House, RnB and the
            lisgoes on. I play at international gigs, festivals, carnivalsweddings and so forth. I host and play from time to timeat diverse clubs in Dubai, United Kingdom,
            Malaysia,Indonesia, Tanzania, Zimbabwe,Z ambia, Myanmar,South Africa, Uganda, Rwanda, Turkey, Georgia,Cyprus, Mozambique and the list goes on.
            My endorsed clothing line launched 8 years ago and I have over time fashioned different collections with social and environmental themes, culture
            appreciation themes and all my collections available on Instagram @flavamusic_merchandise as well as my personal account, @djflava_. I recently
            re-created my winter all over logo tracksuit in white and black colour with a touch of street style. I also buy and sell cars internationally and have them
            delivered at various locations across the world. As for my clothing line, we take orders and deliver across the world, orders can be places through our

            Instagram pages. Our prices are unbeatable, and we are uniquely the best clothing brand you can find. We always go the extra mile to get our
            products to our customers and to rise above their expectations."
            />
            <QuestionComponent question="What's your best piece of advice for readers who desire to find success in their life?" />
            <AnswerComponent answer="Always strive to accomplish your goals, never give up and keep your dream alive." />
            <QuestionComponent question="Speaking of success, what does the word mean to you?" />
            <AnswerComponent answer="Achievement, fulfilment and a mission accomplished." />
            <QuestionComponent question="What's next for you?" />
            <AnswerComponent
              answer="To acquire more knowledge in the industry and accomplish my goals. I would like to expand my clothing ine. I hope to be a role model for
            aspiring DJ’s across the world. Africa to the world!"
            />
          </div>
        </Modal>
      </div>
      <AppLayout toggle="bio">
        <div className="relative lg:px-72 bg-gray-100 z-20 overflow-hidden p-4">
          <div className="flex">
            <div className="h-full w-full pb-28 text-white ">
              <div className="md:grid grid-cols-2 grid-flow-col gap-4">
                <div className="py-8 mt-8">
                  <h1 className="md:text-8xl text-5xl italic text-black font-extrabold mt-8 z-20">
                    Biography
                  </h1>
                  <h1 className="md:text-[300px] text-[100px] md:-mt-[250px] -mt-[80px] italic text-black font-extrabold absolute opacity-5 z-10 overflow-hidden">
                    Biography
                  </h1>
                  <div className="font-bold break-words md:text-justify text-black text-sm tracking-wider">
                    <div className="mt-24">
                      <p className="text-black">
                        <span className="text-6xl text-black italic mr-1">
                          A
                        </span>
                        zeez Jagun better known as DJ Flava, is a Nigerian disc
                        jockey and reputable streetwear entrepreneur.
                      </p>
                    </div>
                    <div className="mt-7">
                      <p className="text-black">
                        DJ Flava started his disc jockey career in 2001 in South
                        East London. In 2009 he decided to take his career
                        further and moved to Kuala Lumpur, Malaysia.
                      </p>
                    </div>
                    <div className="mt-7">
                      <p className="text-black">
                        He is currently based in Dubai, UAE. DJ Flava has played
                        at world famous events and worked with world famous
                        artists.
                      </p>
                    </div>
                    <div className="mt-7">
                      <p className="text-black">
                        Having had a successful fan base DJ Flava collaborated
                        with a designer for the introduction of his official
                        merchandise under the brand FlavaMusic. The brand works
                        in close reference to music and street fashion
                        celebrating new trends and tradition. The launch of his
                        official merchandise further helps portray DJ Flava's
                        sense in style in close reference to the world of art
                        and culture. However his motto has always been
                        "Music...Then Business."
                      </p>
                    </div>
                    <div className="mt-7">
                      <p className="text-black">
                        The brand is now noticed all over the world including
                        Africa, Asia, Europe and The Middle East. The official
                        hashtag for his brand is{" "}
                        <span className="font-extrabold text-xencode-gold">
                          #FlavaMusic #HearltWearlt
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-center absolute float-right right-0 hidden lg:block z-10 md:mr-20">
              <img
                src={DjFlavaColorMain}
                width={600}
                alt="background"
                className="bg-transparant z-30"
              />
            </div>
          </div>
        </div>
        <div className="bg-black">
          <div className="z-40 w-full justify-center flex h-full py-8">
            <div>
              <h1 className="font-bold font-poppins text-xencode-gold text-2xl text-center">
                AWARDS AND NOMINATIONS
              </h1>
              <div className="md:grid md:grid-cols-3 gap-5 text-white text-center mt-4 p-8 content-center">
                <div className="text-left p-4">
                  <h1 className="font-bold">Nominations:</h1>
                  <ul className="list-disc w-full text-sm text-gray-300 mt-2">
                    <li>
                      World Best DJ Nigerian Entertainment Awards (NEA) New
                      York, 2011
                    </li>
                    <li>
                      World Best DJ Nigerian Entertainment Awards (NEA) New
                      York, 2012
                    </li>
                    <li>African Global DJ Awards, South Africa, 2013</li>
                    <li>
                      Best African DJ at the African Social Awards (ASAM)
                      Malaysia, 2014
                    </li>
                    <li>
                      Best African DJ at the African Gala Awards (AGA) Malaysia,
                      2014
                    </li>
                    <li>
                      Best African DJ at the African Social Awards (ASAM)
                      Malaysia, 2015
                    </li>
                    <li>
                      Best African DJ at the African Gala Awards (AGA) Malaysia,
                      2015
                    </li>
                  </ul>
                </div>
                <div className="text-left p-4">
                  <h1 className="font-bold">Awards Won:</h1>
                  <ul className="list-disc w-full text-sm text-gray-300 mt-2">
                    <li>
                      Best African DJ at the African Entertainment Awards
                      (AEA)Malaysia, 2010
                    </li>
                    <li>
                      Best African DJ at the African Entertainment Awards (AEA)
                      Malaysia, 2011
                    </li>
                    <li>
                      Best African DJ at the African Entertainment Awards (AEA)
                      Malaysia, 2012
                    </li>
                    <li>
                      Best African DJ at the African Social Awards (ASAM)
                      Malaysia, 2011
                    </li>
                    <li>
                      Best African DJ at the African Social Awards (ASAM)
                      Malaysia, 2013
                    </li>
                    <li>
                      Best African DJ at the African Social Awards (ASAM)
                      Malaysia, 2016
                    </li>
                    <li>
                      Best Dressed Male at the Damansara Union Council,
                      Malaysia, 2017
                    </li>
                    <li>
                      Best Dressed Male at the Damansara Union Council,
                      Malaysia, 2018
                    </li>
                  </ul>
                </div>
                <div className="text-left p-4">
                  <h1 className="font-bold">Discography:</h1>
                  <ul className="list-disc w-full text-sm text-gray-300 mt-2">
                    <li>Let's Party Naija Mixtape Volumes 1- 45</li>
                    <li>Disturbing DSM Mixtape Volumes 1 & 2</li>
                    <li>Disturbing Uganda Mixtape Volumes 1- 5</li>
                    <li>Traptown Mixtape Volumes 1- 12</li>
                    <li>Pushin Piano Mixtape Volumes 1- 18</li>
                    <li>Dancehall Mixtape Volumes 1- 5</li>
                    <li>West Meets East Africa Mixtape</li>
                    <li>Back to the 90's Mixtape</li>
                    <li>Throwback Naija Mixtape</li>
                    <li>Disturbing Kenya Mixtape</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <button
            onClick={handleOpen}
            className={`p-2 mx-4 absolute text-black font-semibold bg-xencode-gold hover:text-white rounded-3xl px-12 outline-none -mt-[20px]`}
            type="button"
          >
            CLICK HERE TO VIEW INTERVIEW WITH DJ FLAVA
          </button>
        </div>
        <div className="bg-white">
          <div className="z-40 w-full justify-center flex h-full">
            <div className="md:grid md:grid-cols-3 gap-8 text-white text-center my-4 p-8 w-full">
              <div>
                <div className="flex">
                  <img
                    src={HeadphonesLogo}
                    alt="background"
                    className="z-30 self-center w-14 m-2"
                  />
                  <p className="text-black text-left self-center">
                    DJ since: 2001
                  </p>
                </div>
                <div className="flex">
                  <img
                    src={DurationLogo}
                    alt="background"
                    className="z-30 self-center w-14 m-2"
                  />
                  <p className="text-black text-left self-center">
                    Set Duration: 1-6 hours
                  </p>
                </div>
              </div>
              <div>
                <div className="flex">
                  <img
                    src={MediaLogo}
                    alt="background"
                    className="z-30 self-center w-14 m-2"
                  />
                  <p className="text-black text-left self-center">
                    Media Type: Vinyl & Digital
                  </p>
                </div>
                <div className="flex">
                  <img
                    src={CategoriesLogo}
                    alt="background"
                    className="z-30 self-center w-10 mx-4 m-2"
                  />
                  <p className="text-black text-left self-center">
                    Categories: Events, Clubs and Weddings
                  </p>
                </div>
              </div>
              <div>
                <div className="flex">
                  <img
                    src={GenreLogo}
                    alt="background"
                    className="z-30 self-center w-14 m-2"
                  />
                  <p className="text-black text-left self-center">
                    Main Genres: Afrobeats, Hip-Hop, Dancehall and Amapiano
                  </p>
                </div>
                <div className="flex">
                  <img
                    src={GenresLogo}
                    alt="background"
                    className="z-30 self-center w-14 m-2"
                  />
                  <p className="text-black text-left self-center">
                    Genres: Hip-Hop, Old School, Afrobeats, R&B, House,
                    Amapiano, Reggaetón, Dancehall, Disco and Progressive House,
                    Electronic, Bongo Flava
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-black flex md:bg-bio-screen md:pb-32">
          <div className="md:grid md:grid-cols-3 gap-2 text-white text-center p-8 w-full z-40">
            <div className="md:space-y-40 z-10 self-center space-y-8">
              <div className="md:text-right mt-4">
                <h1 className="text-white font-bold bg-gradient-to-r p-[6px] from-black to-xencode-gold">
                  Social Media
                </h1>
                <p className="text-gray-200">Fashion/Merchandise Design</p>
              </div>
              <div className="md:text-right">
                <h1 className="text-white font-bold bg-gradient-to-r p-[6px] from-black to-xencode-gold">
                  Hobbies
                </h1>
                <p className="text-gray-200">Music, Fashion, Food and Travel</p>
              </div>
              <div className="md:text-right">
                <h1 className="text-white font-bold bg-gradient-to-r p-[6px] from-black to-xencode-gold">
                  Venues Played in Dubai
                </h1>
                <p className="text-gray-200">
                  Sky Lounge, KQ Dubai, Marq Dubai, Club Blu, Dark Room, Vii,
                  Club Boudoir, Orange Chameleon, Billionaire Club, Vice Club,
                  Plaza Palace, The Boss
                </p>
              </div>
            </div>
            <div className="flex justify-center mt-24" />
            <div className="md:space-y-40 z-10 self-center space-y-8">
              <div className="md:text-left mt-4">
                <h1 className="text-white font-bold bg-gradient-to-r p-[6px] from-xencode-gold to-black">
                  Creative Fields
                </h1>
                <p className="text-gray-200">Audio and Video Mixtape</p>
              </div>
              <div className="md:text-left">
                <h1 className="text-white font-bold bg-gradient-to-r p-[6px] from-xencode-gold to-black">
                  Languages
                </h1>
                <p className="text-gray-200">English and Yoruba</p>
              </div>
              <div className="md:text-left">
                <h1 className="text-white font-bold bg-gradient-to-r p-[6px] from-xencode-gold to-black">
                  Venues Played in Kuala Lumpur
                </h1>
                <p className="text-gray-200">
                  Trak, Vip Room, Elementz, Long Bar, Club HQ, Next Door, China
                  Bar, Play Empire, V12 Elevate, Club Likwid, Club Prime,
                  Titanium Dance Club, Vogue The Club and Club Rain.
                </p>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
};
