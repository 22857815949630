import React from "react";
import { useHistory } from "react-router-dom";

interface AppProps {
  toggle?: string;
}

export const Footer: React.FC<AppProps> = ({ toggle }) => {
  const history = useHistory();

  const _home = () => {
    history.push("/home");
  };

  const _bio = () => {
    history.push("/bio");
  };

  const _merchandise = () => {
    history.push("/merchandise");
  };

  const _contact = () => {
    history.push("/contact");
  };

  const _events = () => {
    history.push("/events");
  };

  const _mixtapes = () => {
    history.push("/mixtapes");
  };

  const toXenCodeWebsite = () => {
    window.location.replace("https://www.xencode.co.za");
  };

  const DjFlavaLogo = require("../../../assets/images/logo/logo-white.png");
  const DjFlavaInstagram = require("../../../assets/images/footer/instagram.png");
  const DjFlavaSnapchat = require("../../../assets/images/footer/snapchat.png");
  const DjFlavaTikTok = require("../../../assets/images/footer/tiktok.png");
  const DjFlavaTwitter = require("../../../assets/images/footer/twitter.png");

  return (
    <>
      <div className="bg-black w-full flex justify-center py-8 space-x-4">
        <div className="p-4 bg-gray-900 hover:bg-xencode-gold rounded-md">
          <a href="https://www.instagram.com/djflava_/?igshid=YmMyMTA2M2Y%3D">
            <img
              src={DjFlavaInstagram}
              alt="Instagram"
              className="w-10 hover:cursor-pointer"
            />
          </a>
        </div>
        <div className="p-4 bg-gray-900 hover:bg-xencode-gold rounded-md">
          <a href="https://www.snapchat.com/add/flavamusic?share_id=9/Xi7gDRQVyf6PmwLKrATA&locale=en_US&sid=fd8569a1e2174d588d90d92c141e6f47">
            <img
              src={DjFlavaSnapchat}
              alt="Snapchat"
              className="w-10 hover:cursor-pointer"
            />
          </a>
        </div>
        <div className="p-4 bg-gray-900 hover:bg-xencode-gold rounded-md">
          <a href="https://www.tiktok.com/@djflava_?_r=1&_d=dma5d9agjc1g2m&language=en&sec_uid=MS4wLjABAAAApkDox4oIiIELp3wb7kei12AWzhug0WLRNhZ2YSG83fiy9xXmRnRQzHn4JknFyUUr&share_author_id=7044353279652463618&source=h5_t&u_code=dma5d2a7mlel5j&ug_btm=b8727,b0&sec_user_id=MS4wLjABAAAApkDox4oIiIELp3wb7kei12AWzhug0WLRNhZ2YSG83fiy9xXmRnRQzHn4JknFyUUr&utm_source=more&utm_campaign=client_share&utm_medium=ios&tt_from=more&user_id=7044353279652463618&share_link_id=67BED180-C093-4342-AD15-161C7C456FA9&share_app_id=1180">
            <img
              src={DjFlavaTikTok}
              alt="TikTok"
              className="w-10 hover:cursor-pointer"
            />
          </a>
        </div>
        <div className="p-4 bg-gray-900 hover:bg-xencode-gold rounded-md">
          <a href="https://twitter.com/djflava_?t=f8eh8obEMW9ogq1rxixoBA&s=09">
            <img
              src={DjFlavaTwitter}
              alt="Twitter"
              className="w-10 hover:cursor-pointer"
            />
          </a>
        </div>
      </div>
      <div className="bg-black justify-evenly md:flex z-40">
        <div className="md:grid grid-cols-2 grid-flow-col gap-3 px-4 py-4">
          <div>
            <img
              src={DjFlavaLogo}
              alt="Logo"
              className="w-32 hover:cursor-pointer"
            />
          </div>
          <div className="md:grid grid-flow-col gap-6 justify-center items-center text-[12px] p-6 text-white md:space-y-0 space-y-4">
            <p
              className={`hover:cursor-pointer ${
                toggle === "home"
                  ? "text-xencode-gold"
                  : "hover:text-xencode-gold"
              }`}
              onClick={_home}
            >
              HOME
            </p>
            <p
              className={`hover:cursor-pointer ${
                toggle === "bio"
                  ? "text-xencode-gold"
                  : "hover:text-xencode-gold"
              }`}
              onClick={_bio}
            >
              BIO
            </p>
            <p
              className={`hover:cursor-pointer ${
                toggle === "merch"
                  ? "text-xencode-gold"
                  : "hover:text-xencode-gold"
              }`}
              onClick={_merchandise}
            >
              MERCHANDISE
            </p>
            <p
              className={`hover:cursor-pointer ${
                toggle === "events"
                  ? "text-xencode-gold"
                  : "hover:text-xencode-gold"
              }`}
              onClick={_events}
            >
              EVENTS
            </p>
            <p
              className={`hover:cursor-pointer ${
                toggle === "mixtapes"
                  ? "text-xencode-gold"
                  : "hover:text-xencode-gold"
              }`}
              onClick={_mixtapes}
            >
              MIXTAPES
            </p>
            <p
              className={`hover:cursor-pointer ${
                toggle === "contact"
                  ? "text-xencode-gold"
                  : "hover:text-xencode-gold"
              }`}
              onClick={_contact}
            >
              CONTACT
            </p>
          </div>
        </div>
      </div>
      <div className="lg:h-8 h-12 bg-black lg:px-72 px-4 md:grid grid-cols-2 grid-flow-col gap-4 justify-center pt-2 text-xs">
        <div className="text-gray-500">
          <p className="text-gray-500">© COPYRIGHT 2023. ALL RIGHTS RESERVED</p>
        </div>
        <div className="md:text-right text-left">
          <p className="text-gray-500">
            WEBSITE DESIGN BY{" "}
            <span
              onClick={toXenCodeWebsite}
              className="text-xencode-gold hover:cursor-pointer hover:text-white"
            >
              XenCode
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
